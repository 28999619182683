import React from "react";
import useDeviceType from "../utils/useDeviceType";

const Input = ({ label, type, id, name, value, onChange, placeholder }) => {
  const deviceType = useDeviceType();
  return (
    <div style={{ height: "70px", marginBottom: "10px" }}>
      <label htmlFor={id} style={{ marginBottom: "5px" }}>{label}</label> <br />
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        style={
          deviceType === "mobile"
            ? {
                width: "310px",
                height: "40px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }
            : {
                width: "350px",
                height: "40px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }
        }
      />
    </div>
  );
};

export default Input;