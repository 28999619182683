import React from "react";
import logo from "../assets/images/logo.png";
import "../App.css";
import useDeviceType from "../utils/useDeviceType";
import { headerMenus } from "../json/headerMenus";
import { useNavigate } from 'react-router-dom';


const Footer = () => {
  const navigate = useNavigate();


  const deviceType = useDeviceType();
  const isMobile = deviceType === 'mobile';

  return (
    <footer className="footer" style={{
      boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.1)",
    }}>
      <div 
      className={`footer-container ${isMobile ? 'mobile' : 'desktop'}`}
      >
        {/* Logo Section */}
        <div className="footer-logo-section">
          <div className="footer-logo">
            <img alt="Logo" loading="lazy" src={logo} />
          </div>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <svg stroke="currentColor" fill="currentColor" viewBox="0 0 320 512" height="1em" width="2em">
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"></path>
              </svg>
            </a>
            <a href="https://www.linkedin.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
              <svg stroke="currentColor" fill="currentColor" viewBox="0 0 448 512" height="1em" width="2em">
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3c94 0 111.28 61.9 111.28 142.3V448z"></path>
              </svg>
            </a>
            <a href="https://www.instagram.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <svg stroke="currentColor" fill="currentColor" viewBox="0 0 512 512" height="1em" width="2em">
                <path d="M336 96c21.2 0 41.3 8.4 56.5 23.5S416 154.8 416 176v160c0 21.2-8.4 41.3-23.5 56.5S357.2 416 336 416H176c-21.2 0-41.3-8.4-56.5-23.5S96 357.2 96 336V176c0-21.2 8.4-41.3 23.5-56.5S154.8 96 176 96h160m0-32H176c-61.6 0-112 50.4-112 112v160c0 61.6 50.4 112 112 112h160c61.6 0 112-50.4 112-112V176c0-61.6-50.4-112-112-112z"></path>
                <path d="M360 176c-13.3 0-24-10.7-24-24s10.7-24 24-24c13.2 0 24 10.7 24 24s-10.8 24-24 24zM256 192c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64m0-32c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96z"></path>
              </svg>
            </a>
            <a href="https://www.youtube.com/yourpage" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <svg stroke="currentColor" fill="currentColor" viewBox="0 0 576 512" height="1em" width="2em">
                <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
              </svg>
            </a>
          </div>
        </div>

        {/* Columns Section */}
        <div className="footer-columns">
          {/* Quick Links */}
          <div className="footer-column">
            <p className="footer-column-title">Quick Links</p>
            {headerMenus.map((menu, index) => (
              <p 
                key={index} // Add a unique key for each element
                className="footer-link"
                onClick={() => navigate(menu?.path)}
              >
                {menu?.name}
              </p>
            ))}
          </div>

          {/* Contact Section */}
          {/* <div className="footer-column">
            <p className="footer-column-title">Contact Us</p>
            <ul className="footer-contact-list">
              <li style={{ height: 'auto'}}>
                <a href="mailto:info@example.com" className="footer-contact-link">info@example.com</a>
              </li>
              <li style={{ height: 'auto'}}>
                <a href="tel:+1234567890" className="footer-contact-link">+1 234 567 890</a>
              </li>
              <li style={{ height: 'auto'}} className="footer-contact-item">123 Main St, City, Country</li>
            </ul>
          </div> */}
            <div className="footer-column">
            <p className="footer-column-title">Contact Us</p>
            <p className="footer-link">
            <a href="mailto:info@example.com" className="footer-contact-link">info@example.com</a>
            </p>
            <p className="footer-link">
            <a href="tel:+1234567890" className="footer-contact-link">+1 234 567 890</a>
            </p>
            <p className="footer-link">
            123 Main St, City, Country
            </p>
          </div>
        </div>
      </div>

      {/* Copyright Section */}
      <div className="footer-copyright">
        <p>
          © 2023 <a href="/" className="footer-copyright-link">Elora Art</a>. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;