import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, photos = [], currentIndex }) => {
  if (!isOpen || photos.length === 0) return null;

  return ReactDOM.createPortal(
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        {/* Access the photo URL directly based on the currentIndex */}
        <img
          src={photos[currentIndex]}
          alt={`Modal ${currentIndex + 1}`}
          style={modalStyles.image}
        />
        <button onClick={onClose} style={modalStyles.closeButton}>Close</button>
      </div>
    </div>,
    document.body
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'start',
    width: 'auto',
    height: 'auto',
    position: 'relative',
  },
  closeButton: {
    border: 'none',
    backgroundColor: '#FF6347',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    height: 'auto',
    width: '100%',
    marginTop: '10px', // Add margin to separate the button from the image
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

export default Modal;