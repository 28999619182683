import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDeviceType from "../utils/useDeviceType";
import servicesGifPath from "../assets/videos/ourServicesGif.mp4";
import Layout from "./Layout";
import axios from "axios";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const videoRef = useRef(null);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const fallbackClickableAreas = [
    { name: "Retail Branding", top: deviceType === "mobile" ? "49%" : "44.5%", left: deviceType === "mobile" ? "24%" : "28%" },
    { name: "Laser & Cnc Cutting", top: deviceType === "mobile" ? "42%" : "25.5%", left: deviceType === "mobile" ? "22%" : "26%" },
    { name: "3D Acrylic Letter", top: deviceType === "mobile" ? "36%" : "9.5%", left: deviceType === "mobile" ? "26%" : "30%" },
    { name: "Vehicle Branding", top: deviceType === "mobile" ? "35.5%" : "9.5%", right: deviceType === "mobile" ? "25%" : "29%" },
    { name: "Franchise Store", top: deviceType === "mobile" ? "42%" : "25.5%", right: deviceType === "mobile" ? "22%" : "27%" },
    { name: "Event & Exhibition", top: deviceType === "mobile" ? "48.5%" : "44%", right: deviceType === "mobile" ? "23%" : "27%" },
  ];

  const fetchAllServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/get-category-data`, { category_name: categoryName });
      console.log("API response:", response);
      setServices(response.data ?? []);
    } catch (error) {
      console.error("Error fetching services:", error);
      setError("Failed to fetch services. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [API_URL, categoryName]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const handleVideoPlay = () => {
    setIsVideoPlayed(true);
    sessionStorage.setItem("videoPlayed", "true");
  };

  useEffect(() => {
    const hasPlayed = sessionStorage.getItem("videoPlayed");
    const video = videoRef.current;

    if (video) {
      video.addEventListener("loadedmetadata", () => {
        if (hasPlayed) {
          setIsVideoPlayed(true);
          video.currentTime = video.duration; // Skip the video if it has been played
          video.pause();
        } else {
          video.muted = true;
          video.play()
            .then(() => {
              console.log("Video autoplayed successfully");
            })
            .catch((err) => {
              console.error("Video autoplay failed:", err);
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("videoPlayed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBranchClick = (categoryName) => {
    if (isVideoPlayed) {
      navigate(`/services/${categoryName}`);
    }
  };

  return (
    <Layout>
      <div
        style={
          deviceType === "mobile"
            ? {
                backgroundColor: "transparent",
                position: "relative",
                height: "100%",
                width: "100%",
                padding: "0px 0px",
              }
            : {
                backgroundColor: "transparent",
                position: "relative",
                height: "100%",
                width: "100%",
                padding: "50px 20px 120px 20px",
              }
        }
      >
        {loading && <p>Loading services...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}

        <video
          ref={videoRef}
          src={servicesGifPath}
          type="video/mp4"
          width="100%"
          autoPlay
          muted
          loop
          playsInline
          onPlay={handleVideoPlay}
          controls={false}
        />

        {services.length > 0
          ? services.map(({ categoryName, top, left, right }, index) => (
              <div
                key={index}
                onClick={() => handleBranchClick(categoryName)}
                style={{
                  position: "absolute",
                  top: top || fallbackClickableAreas[index]?.top,
                  left: left || fallbackClickableAreas[index]?.left,
                  right: right || fallbackClickableAreas[index]?.right,
                  width: deviceType === "mobile" ? "11%" : "14%",
                  height: deviceType === "mobile" ? "6%" : "15%",
                  cursor: isVideoPlayed ? "pointer" : "default",
                  pointerEvents: isVideoPlayed ? "auto" : "none",
                }}
              ></div>
            ))
          : fallbackClickableAreas.map(({ name, top, left, right }, index) => (
              <div
                key={index}
                onClick={() => handleBranchClick(name)}
                style={{
                  position: "absolute",
                  top,
                  left,
                  right,
                  width: deviceType === "mobile" ? "11%" : "14%",
                  height: deviceType === "mobile" ? "6%" : "15%",
                  cursor: isVideoPlayed ? "pointer" : "default",
                  pointerEvents: isVideoPlayed ? "auto" : "none",
                }}
              ></div>
            ))}
      </div>
    </Layout>
  );
};

export default Services;
