import React, { useState } from "react";
import Layout from "./Layout";
import Input from "../components/Input";
import axios from "axios";
import useDeviceType from "../utils/useDeviceType";
import fashionImage from "../assets/images/demo/business.jpg";
import TextArea from "../components/Textarea";

const ContactUs = () => {
  const deviceType = useDeviceType();
  const API_URL = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/store-enquiry`, formData);
      setResponseMessage("Enquiry submitted successfully!");
      setFormData({
        full_name: "",
        email: "",
        mobile: "",
        subject: "",
        message: "",
      });
      setValidationErrors({});
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setValidationErrors(error.response.data.errors);
      } else {
        setResponseMessage("Failed to submit enquiry. Please try again.");
      }
      console.error("Error submitting enquiry:", error);
    }
  };

  return (
    <Layout>
      <div
        style={
          deviceType === "mobile"
            ? {
                paddingTop: "0px",
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }
            : {
                padding: "0 55px",
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }
        }
      >
        {/* Contact Details Section */}
        <div
          style={
            deviceType === "mobile"
              ? {
                  width: "90%",
                  backgroundColor: "#FF6347",
                  margin: "20px", 
                  padding: "20px",
                  borderRadius: "8px",
                }
              : {
                  width: "35%",
                  height: "auto",
                  margin: "20px",
                  padding: "20px",
                  backgroundColor: "#FF6347",
                  border: "1px solid #c8d8de",
                  borderRadius: "8px",
                }
          }
        >
          <h2 style={{ color: "white", fontWeight: 600 }}>Mail Address</h2>
          <hr
            style={{
              borderTop: "1.5px solid white",
              margin: "2px 0px",
              width: "80%",
            }}
          />
          <div style={{ marginTop: "20px", color: "white" }}>
            <p>
              <strong>raju@eloraart.com </strong> 
            </p>
            <p>
              <strong> rajueloraart@gmail.com</strong>
            </p>
            <p>
              <strong>  rahul@eloraart.com </strong>
            </p>
            <p>
              <strong> rahuleloraart@gmail.com </strong>
            </p>
           
          </div>
        </div>

        <div
          style={
            deviceType === "mobile"
              ? {
                  width: "90%",
                  backgroundColor: "#FF6347",
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "8px",
                }
              : {
                  width: "35%",
                  height: "auto",
                  margin: "20px",
                  padding: "20px",
                  border: "1px solid #c8d8de",
                  backgroundColor: "#FF6347",
                  borderRadius: "8px",
                }
          }
        >
          <h2 style={{ color: "white", fontWeight: 600 }}>Office Location</h2>
          <hr
            style={{
              borderTop: "1.5px solid white",
              margin: "2px 0px",
              width: "80%",
            }}
          />
          <div style={{ marginTop: "20px", color: "white" }}>
            <p>
              <strong>Branch Office :</strong> Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440 012
              1Y7
            </p>
            <p>
              <strong>Branch Office :</strong> Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440 012
              1Y7
            </p>
            <p>
              <strong>Branch Office :</strong> Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440 012
              1Y7
            </p>
          </div>
        </div>

        <div
          style={
            deviceType === "mobile"
              ? {
                  width: "90%",
                  backgroundColor: "#FF6347",
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "8px",
                }
              : {
                  width: "35%",
                  height: "auto",
                  margin: "20px",
                  padding: "20px",
                  backgroundColor: "#FF6347",
                  border: "1px solid #c8d8de",
                  borderRadius: "8px",
                }
          }
        >
          <h2 style={{ color: "white", fontWeight: 600 }}>Phone Number</h2>
          <hr
            style={{
              borderTop: "1.5px solid white",
              margin: "2px 0px",
              width: "80%",
            }}
          />
          <div style={{ marginTop: "20px", color: "white" }}>
            <p>
              <strong>Call Us:</strong> +91 99201 53533
            </p>
            <p>
              <strong>Call Us:</strong> +91 72083 41924
            </p>
          </div>
        </div>

        
        {/* Contact Form Section */}
      </div>

      <section
          style={
            deviceType === "mobile" ? {
              display: "flex",
              height: "auto",
              flexDirection: deviceType === 'mobile' ? "column" : "row",
              alignItems: "center",
              padding: "0 20px",
              marginBottom: "50px",
            } : {
              display: "flex",
              height: "auto",
              flexDirection: deviceType === 'mobile' ? "column" : "row",
              alignItems: "center",
              padding: "0 55px",
              marginBottom: "50px",
            }
          }
        >
          <div
            style={{
              flex: "1",
              padding: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={fashionImage}
              alt="About Us"
              style={
                deviceType === 'mobile'
                  ? {
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }
                  : {
                      width: "100%",
                      height: "50vh",
                      borderRadius: "10px",
                  }
              }
            />
          </div>
          <div
            style={
              deviceType === "mobile"
                ? {
                    width: "100%",
                    backgroundColor: "rgb(239, 239, 239)",
                    margin: "20px",
                    padding: "20px",
                    borderRadius: "8px",
                  }
                : {
                    width: "60%",
                    height: "50vh",
                    backgroundColor: "rgb(239, 239, 239)",
                    // display: "flex",
                    // flexDirection: "column",
                    // justifyContent: "center",
                    // alignItems: "center",
                   
                    padding: "20px",
                    borderRadius: "8px",
                  }
            }
          >
         
          <div
            style={
              deviceType === "mobile"
                ? {
                    display: "flex",
                    flexDirection: "wrap",
                    gap: "20px",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: "20px",
                  }
            }
          >
            <form onSubmit={handleSubmit}>
              <div style={
                    deviceType === "mobile" ? {
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                    } : {
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      height: "auto",
                    }
              }>
                <Input
                  label="Full Name"
                  placeholder={"Enter your full name"}
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                  style={{ flex: "2", marginBottom: "10px" }}
                />
                {/* {validationErrors.full_name && (
                  <p style={{ color: "red" }}>{validationErrors.full_name}</p>
                )} */}
                <Input
                  label="Email"
                  placeholder={"Enter your email"}
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ flex: "1", marginBottom: "10px" }}
                />
                {/* {validationErrors.email && (
                  <p style={{ color: "red" }}>{validationErrors.email}</p>
                )} */}
            </div>

            <div 
                style={
                  deviceType === "mobile" ? {
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                  } : {
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    height: "auto",
                  }
                }
            >
              <Input
                label="Mobile Number"
                placeholder={"Enter your mobile number"}
                type="number"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
                style={{ flex: "1", marginBottom: "10px" }}
              />
              {/* {validationErrors.mobile && (
                <p style={{ color: "red" }}>{validationErrors.mobile}</p>
              )} */}
              <Input
                label="Subject"
                placeholder={"Enter your subject"}
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                style={{ flex: "1", marginBottom: "10px" }}
              />
              {/* {validationErrors.subject && (
                <p style={{ color: "red" }}>{validationErrors.subject}</p>
              )} */}
            </div>

            <div
               style={{display: "flex",
                flexDirection: "row",
                gap: "20px",
                height: "20vh",
                }}
            >
            <TextArea
                label="Message"
                placeholder={"Enter your message"}
                type="textarea"
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
                style={{ flex: "1",  width: "100%" }}
              />
            </div>
                
              {/* {validationErrors.message && (
                <p style={{ color: "red" }}>{validationErrors.message}</p>
              )} */}

              <div
                style={
                  {
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
                }
              >
              <button
                type="submit"
                style={
                  deviceType === "mobile" ? {
                    width: "50%",
                    color: "white",
                    backgroundColor: "rgb(73, 130, 207)",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  } : {
                    width: "50%",
                    color: "white",
                    backgroundColor: "rgb(73, 130, 207)",
                    border: "none",
                    padding: "10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "16px",
                  }
                }
              >
                Submit
              </button>
              </div>
              {responseMessage && (
                <p style={{ marginTop: "10px", color: "green" }}>
                  {responseMessage}
                </p>
              )}
            </form>
          </div>
        </div>
        </section>

      {/* Map */}
      <div style={
            deviceType === "mobile"
              ? {
                  width: "100%",
                  height: "400px",
                  marginTop: "20px",
                  padding: "0 30px",
                  borderRadius: "8px",
                }
              : {
                  width: "100%",
                  height: "400px",
                  marginTop: "20px",
                  padding: "0 60px",
                  border: "1px solid #c8d8de",
                  borderRadius: "8px",
              }
      }>
        <iframe
          title="vs"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.950119756272!2d72.84280547497937!3d19.153660582066898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b71c79164b23%3A0xae40753a8612ec9d!2sElora%20Art%20-%20The%20Brand%20Promotion!5e0!3m2!1sen!2sin!4v1726665664186!5m2!1sen!2sin"
          width="100%"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </Layout>
  );
};

export default ContactUs;