import React from 'react';
import Layout from './Layout';
import fashionImage from '../assets/images/demo/business.jpg'; 
import clients from '../assets/images/desktop-clients.png';
import clientsMobile from '../assets/images/mobile-clients.jpeg';
import useDeviceType from '../utils/useDeviceType';

const AboutUs = () => {
  const deviceType = useDeviceType();

  return (
    <Layout>
      <div
        style={{
          height: "auto",
          width: "100%",
          padding: deviceType === 'mobile' ? "20px" : "50px",
          boxSizing: "border-box",
          backgroundColor: "transparent",
          // overflowY: "auto",
        }}
      >
        <section
          style={{
            display: "flex",
            height: "auto",
            flexDirection: deviceType === 'mobile' ? "column" : "row",
            alignItems: "center",                                   
            marginBottom: "50px",
          }}
        >
          <div
            style={{
              flex: "1",
              padding: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={fashionImage}
              alt="About Us"
              style={
                deviceType === 'mobile'
                  ? {
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }
                  : {
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                  }
              }
            />
          </div>
          <div
            style={{
              flex: "2",
              padding: deviceType === 'mobile' ? "10px 0 0 0" : "20px",
              backgroundColor: deviceType === 'mobile' ? "transparent" : "transparent",
              borderRadius: "10px",
              border: "1px solid #f0f0f0",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)", 
            }
            }
          >
            <h1
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                      marginBottom: "10px",
                      fontSize: "3rem",
                    
                    }
              }
            >About Us</h1>
            <h2
            style={
              deviceType === 'mobile'
                ? {
                    marginBottom: "5px",
                  }
                : {
                  marginBottom: "10px",
                
                  }
            }
            >Our Mission</h2>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              We work hard every day to make Elora Art the world’s most
              respected marketing promotion service provider.
            </p>
            <h2
            style={
              deviceType === 'mobile'
                ? {
                    marginBottom: "5px",
                  }
                : {
                
                  marginBottom: "10px"
                  }
            }
            >Our Vision</h2>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              To be the most passionately referred agency in the Advertising
              industry.
            </p>
            <h2
            style={
              deviceType === 'mobile'
                ? {
                    marginBottom: "5px",
                  }
                : {
                
                  marginBottom: "10px"
                  }
            }
            >What we are?</h2>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              ELORA ART are based in Mumbai-Maharashtra proud to announce that
              we are one of leading Retail advertisement solutions provider for
              last 20 Years. Where we cover all ATL and BTL activities through
              our strong network in pan India level basis.
            </p>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              Our creative ideas and their brilliant execution in the most
              economical way distinguishes us from our competitors, and helps
              achieve our goal utilizing state of the art large format digital
              printers as per below.
            </p>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              We have strong marketing force to give better idea about our
              industries and services at any precise given time at preeminent
              feasible rate. Our Business success is built on our commitment not
              only to technical excellence and product quality but also on
              customer satisfaction.
            </p>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                       
                      marginBottom: "10px",
                    }
              }
            >
              The team provides specialized services in creative designing,
              sales, marketing & technical support along with the services to
              meet the challenging requirements of the Modern day customer and
              giving you the assurance of highest possible quality every time.
            </p>
            <p
              style={
                deviceType === 'mobile'
                  ? {
                      marginBottom: "5px",
                    }
                  : {
                    
                      marginBottom: "10px",
                    }
              }
            >
              We aim to serve a broad spectrum of clients with quality products,
              prompt execution of projects at affordable prices.
            </p>
          </div>
        </section>

        <section style={{ marginTop: "50px", height: "auto" }}>
          <div>
            <h2 style={{ textAlign: "center" }}>Our Valuable Client's</h2>
          </div>
          <div
            style={{
              padding: deviceType === 'mobile' ? "20px" : "22px",
            }}
          >
               <img
                  src={deviceType === 'mobile' ? clientsMobile : clients}
              />
          </div>
        </section>
          
      </div>
    </Layout>
  );
};

export default AboutUs;
