import React from 'react';

const Network = ({ isOpen, onClose, title, name}) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>  
        <h2 style={{ height: "auto" }}>{title}</h2>
        <p style={{ height: "auto" }}>{name}</p>
        <button onClick={onClose} style={modalStyles.closeButton}>Close</button>
      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    width: '400px',
    height: 'auto',
  },
  closeButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#FF6347',
    border: 'none',
    color: 'white',
    borderRadius: '5px',
    width: '100px',
    height: 'auto',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

export default Network;
