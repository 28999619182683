import React, { useEffect, useRef, useState } from 'react';
import Layout from './Layout';
import useDeviceType from '../utils/useDeviceType';
import OurNetworkGifPath from '../assets/videos/ourNetworkGif.mp4';
import OurNetworkMobileGifPath from '../assets/videos/ourNetworkMobileGif.mp4';
import Network from '../components/Network';

const OurNetwork = () => {
  const deviceType = useDeviceType();
  const videoRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState(''); 
  const clickableAreaHeight = deviceType === "mobile" ? "3%" : "4%";
  const clickableAreaWidth = deviceType === "mobile" ? "4%" : "2%";

  const handleVideoPlay = () => {
    sessionStorage.setItem("videoPlayed", "true");
  };

  useEffect(() => {
    const video = videoRef.current;
    const hasPlayed = sessionStorage.getItem("videoPlayed");

    if (hasPlayed && video) {
      video.addEventListener("loadedmetadata", () => {
        if (video.duration) {
          video.currentTime = video.duration; // Go to the end of the video
          video.pause(); // Pause the video
        }
      });
    }

    return () => {
      if (video) {
        video.removeEventListener("loadedmetadata", () => {});
      }
    };
  }, []);

  const isVideoPlayed = !!sessionStorage.getItem("videoPlayed");

  const clickableAreaStyle = {
    position: "absolute",
    width: clickableAreaWidth,
    height: clickableAreaHeight,
    cursor: isVideoPlayed ? "pointer" : "not-allowed",
    pointerEvents: isVideoPlayed ? "auto" : "none",
  };

  const handleClick = (name, title) => {
    setName(name);
    setTitle(title); // Set title when clicked
    setModalOpen(true);
  };

  return (
    <Layout>
      <div
        style={
          deviceType === "mobile"
            ? {
              backgroundColor: "transparent",
              position: "relative",
              height: "100%",
              width: "100%",
              padding: "10px",
            }
            : {
              backgroundColor: "transparent",
              position: "relative",
              height: "100%",
              width: "100%",
              padding: "100px 0px",
            }
        }
      >
        <video
          ref={videoRef}
          src={deviceType === "mobile" ? OurNetworkMobileGifPath : OurNetworkGifPath}
          type="video/mp4"
          width="100%"
          autoPlay
          muted
          onPlay={handleVideoPlay}
          controls={false}
        ></video>

        {/* Clickable Areas */}
        <div
          style={{ ...clickableAreaStyle, top: deviceType === "mobile" ? "40.5%" : "39%", left: deviceType === "mobile" ? "47.5%" : "50.5%" , cursor: "pointer"}}
          onClick={() => isVideoPlayed && handleClick("D/305, Chandra Nagar, Bhagwanpur, Near Gardaniya School, Lanka Varanasi, Uttar Pradesh 221 005", "Varanasi Branch Office")}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === "mobile" ? "45.5%" : "46.5%", left: deviceType === "mobile" ? "17%" : "40%" }}
          onClick={() => isVideoPlayed && handleClick("456 Another St, City, Country", "Ahmedabad Branch Office")}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === "mobile" ? "47.5%" : "50%", left: deviceType === "mobile" ? "37.5%" : "47%"}}
          onClick={() => isVideoPlayed && handleClick("Sharda Bhavan, Dhondha Chowk, Behind Seva Sadan, C.A. Road, Nagpur 440 012", "Nagpur Branch Office")}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === "mobile" ? "51%" : "56%", left: deviceType === "mobile" ? "17.5%" : "40%"}}
          onClick={() => isVideoPlayed && handleClick("306 Shivam Chamber, Near Ram Mandir Signal, S.V. Road, Goregoan West, Mumbai 400 104, Maharashtra, India", "Registered Office")}
        ></div>
        <div
          style={{ ...clickableAreaStyle, top: deviceType === "mobile" ? "51%" : "56%", left: deviceType === "mobile" ? "22.5%" : "42%"}}
          onClick={() => isVideoPlayed && handleClick("654 Test Blvd, City, Country", "Pune Branch Office")}
        ></div>
        
        <Network isOpen={isModalOpen} onClose={() => setModalOpen(false)} title={title} name={name} /> 
      </div>
    </Layout>
  );
};

export default OurNetwork;
