export const headerMenus = [
  {
    name: "HOME",
    path: "/",
    id: 1,
  },
  {
    name: "SERVICES",
    path: "/services",
    id: 2,
  },
  {
    name: "ABOUT US",
    path: "/about-us",
    id: 3,
  },
  {
    name: "INFRASTRUCTURE",
    path: "/our-infrastructure",
    id: 4,
  },
  {
    name: "OUR TEAM",
    path: "/our-team",
    id: 5,
  },
  {
    name: "OUR NETWORK",
    path: "/our-network",
    id: 6,
  },
  {
    name: "CONTACT US",
    path: "/contact-us",
    id: 7,
  },
];
