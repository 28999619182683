import React, { useEffect, useState, useRef } from 'react';
import Layout from './Layout';
import OurTeamGif from '../assets/videos/ourTeamGif.mp4';
import ourTeamMobileGif from '../assets/videos/ourTeamMobileGif.mp4';
import useDeviceType from '../utils/useDeviceType';
import Team from '../components/Team'; 
import fashionImage from '../assets/images/demo/fashion.jpg';

const OurTeam = () => {
  const deviceType = useDeviceType();
  const videoRef = useRef(null);
  const clickableAreaHeight = deviceType === 'mobile' ? '12%' : '14%';
  const clickableAreaWidth = deviceType === 'mobile' ? '24%' : '8%';
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalPhotos] = useState([
    { src: fashionImage },
    { src: fashionImage }, 
    { src: fashionImage }, 
  ]);
  const titles = ['Designation : Operations Head', 'Designation : Founder', 'Designation : Marketing Head']; 
  const names = ['Name : Mr Rahul Yadav', 'Name : Mr Raju Yadav', 'Name : Ms Simran Anchan']; 

  const handleVideoPlay = () => {
    sessionStorage.setItem("videoPlayed", "true");
  };

  useEffect(() => {
    const hasPlayed = sessionStorage.getItem("videoPlayed");
    const video = videoRef.current;

    if (hasPlayed && video) {
      setIsVideoPlayed(true);
      video.addEventListener("loadedmetadata", () => {
        if (video.duration) {
          video.currentTime = video.duration;
          video.pause();
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("videoPlayed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }; 
  }, []);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <div
        style={
          deviceType === "mobile"
            ? {
              backgroundColor: "transparent",
              position: "relative",
              height: "100%",
              width: "100%",
              padding: "20px ",
            }
            : {
              backgroundColor: "transparent",
              position: "relative",
              height: "100%",
              width: "100%",
              padding: "100px",
            }
        }
      >
        <video
          ref={videoRef}
          src={deviceType === "mobile" ? ourTeamMobileGif : OurTeamGif}
          type="video/mp4"
          width="100%"
          autoPlay
          muted
          onPlay={handleVideoPlay}
          controls={false}
        ></video>

        <div 
          onClick={() => openModal(0)}
          style={{
            position: "absolute",
            top: deviceType === "mobile" ? "45%" : "24.5%",
            left: deviceType === "mobile" ? "14.5%" : "33%",
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: "pointer",
            borderRadius: "50%",
          }}
        ></div>

        <div 
          onClick={() => openModal(1)} 
          style={{
            position: "absolute",
            top: deviceType === "mobile" ? "18%" : "13.5%",
            left: deviceType === "mobile" ? "39%" : "46.5%",
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: "pointer",
            borderRadius: "50%",
          }}
        ></div>

        <div 
          onClick={() => openModal(2)} 
          style={{
            position: "absolute",
            top: deviceType === "mobile" ? "45%" : "22%",
            right: deviceType === "mobile" ? "18%" : "34%",
            width: clickableAreaWidth,
            height: clickableAreaHeight,
            cursor: "pointer",
            borderRadius: "50%",
          }}
        ></div>

        <Team 
          isOpen={modalOpen}
          onClose={closeModal}
          title={titles[currentImageIndex]}
          name={names[currentImageIndex]} 
          imageSrc={modalPhotos[currentImageIndex]?.src} 
        />
      </div>
    </Layout>
  );
};

export default OurTeam;
