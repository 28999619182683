

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import homeGifPath from "../assets/videos/homeGif.mp4";
import homeMobileGifPath from "../assets/videos/homeMobileGif.mp4";
import useDeviceType from "../utils/useDeviceType";

const Home = () => {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const videoRef = useRef(null);
  const clickableAreaHeight = deviceType === "mobile" ? "13%" : "20%";
  const clickableAreaWidth = deviceType === "mobile" ? "22.5%" : "10%";
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const handleBranchClick = (branch) => {
    navigate(branch);
  };

  const handleVideoPlay = () => {
    sessionStorage.setItem("videoPlayed", "true");
  };

  useEffect(() => {
    const hasPlayed = sessionStorage.getItem("videoPlayed");
    const video = videoRef.current;

    if (hasPlayed && video) {
      setIsVideoPlayed(true);
      video.addEventListener("loadedmetadata", () => {
        if (video.duration) {
          video.currentTime = video.duration;
          video.pause();
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("videoPlayed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div    
      style={{
        backgroundColor: "white",
        position: "relative",
        overflow: "hidden",
        height: "100%",
        width: "100%",
      }}
    >
      <video
        ref={videoRef}
        src={
          deviceType === "mobile" ? homeMobileGifPath : homeGifPath
        }
        type="video/mp4"
        width="100%"
        autoPlay
        muted
        onPlay={handleVideoPlay}
        controls={false}
      />

      <div 
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("about-us")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "44%" : "46%",
          left: deviceType === "mobile" ? "12.5%" : "28%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("our-team")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "27%" : "24%",
          left: deviceType === "mobile" ? "2%" : "26%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("services")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "11.5%" : "3%",
          left: deviceType === "mobile" ? "13%" : "29%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("our-network")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "8%" : "1%",
          right: deviceType === "mobile" ? "38%" : "45%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("our-infrastructure")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "12%" : "3%",
          right: deviceType === "mobile" ? "10.5%" : "28.5%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        onClick={() => handleBranchClick("contact-us")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "27%" :"24%",
          right: deviceType === "mobile" ? "5%" : "26.5%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

      <div
        disabled={!isVideoPlayed}
        // onClick={() => handleBranchClick("contact-us")}
        style={{
          position: "absolute",
          top: deviceType === "mobile" ? "44%" :"45%",
          right: deviceType === "mobile" ? "16%" : "27.5%",
          borderRadius: deviceType === "mobile" ? "50%" : "50%",
          width: clickableAreaWidth,
          height: clickableAreaHeight,
          cursor: "pointer",
          // backgroundColor: "yellow",
        }}
      ></div>

    </div>
  );
};

export default Home;
