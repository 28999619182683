import React from 'react';
import useDeviceType from '../utils/useDeviceType';

const Card = ({ image, name, description }) => {
  const deviceType = useDeviceType();
  return (
    <div 
      style={
        deviceType === 'mobile'
          ? {  border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden', width: 'auto', margin: '20px' }
          :  { border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden', width: '425px', margin: '20px' }
      }
    >
      <img src={image} alt={name} style={{ width: '100%', height: '250px', objectFit: 'fill',padding: '10px', borderRadius: '15px' }} />
      <div style={{ padding: '16px' }}>
        <h3 style={{ margin: '0 0 10px 0' }}>{name}</h3>
        <p style={{ margin: '0' }}>{description}</p>
      </div>
    </div>
  );
};

export default Card;