import React from 'react';
import Card from '../components/Card';
import Layout from './Layout';
import fashionImage from "../assets/images/demo/fashion.jpg";
import useDeviceType from '../utils/useDeviceType';

const infrastructureData = [
  {
    image: fashionImage,
    name: 'HP Latex 570',
    description: 'Our creative ideas and their brilliant execution in the most economical way distinguishes us from our competitors, and helps achieve our goal utilizing state of the art large format digital printers as per below. We have strong marketing force to give better idea about our industries and services at any precise given time at preeminent feasible rate. '
  },
  {
    image: fashionImage,
    name: 'HP Latex 570',
    description: 'Our creative ideas and their brilliant execution in the most economical way distinguishes us from our competitors, and helps achieve our goal utilizing state of the art large format digital printers as per below. We have strong marketing force to give better idea about our industries and services at any precise given time at preeminent feasible rate. '
  },
  {
    image: fashionImage,
    name: 'HP Latex 570',
    description: 'Our creative ideas and their brilliant execution in the most economical way distinguishes us from our competitors, and helps achieve our goal utilizing state of the art large format digital printers as per below. We have strong marketing force to give better idea about our industries and services at any precise given time at preeminent feasible rate. '
  },
  {
    image: fashionImage,
    name: 'HP Latex 570',
    description: 'Our creative ideas and their brilliant execution in the most economical way distinguishes us from our competitors, and helps achieve our goal utilizing state of the art large format digital printers as per below. We have strong marketing force to give better idea about our industries and services at any precise given time at preeminent feasible rate. ' 
  },
  {
    image: fashionImage,
    name: 'HP Latex 570',
    description: 'Our creative ideas and their brilliant execution in the most economical way distinguishes us from our competitors, and helps achieve our goal utilizing state of the art large format digital printers as per below. We have strong marketing force to give better idea about our industries and services at any precise given time at preeminent feasible rate. '
  },
  // Add more infrastructure data as needed
];

const OurInfraStructure = () => {
  const deviceType = useDeviceType();
  return (
    <Layout>
      <div 
        style={
          deviceType === 'mobile'
            ? {  height: 'auto', width: '100%', color: 'black', padding: '10px' }
            :  { height: 'auto', width: '100%', color: 'black', padding: '50px', overflowY: 'auto' }
        }
      >
        {/* <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
          Our Infra Structure
        </h1> */}
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'start' }}>
          {infrastructureData.map((infra, index) => (
            <Card key={index} image={infra.image} name={infra.name} description={infra.description} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default OurInfraStructure;