import React from "react";

const TextArea = ({ label, id, name, value, onChange, rows, placeholder }) => {
  const containerStyle = {
    height: "100px",
    marginBottom: "10px",
  };

  const labelStyle = {
    marginBottom: "5px",
  };

  const textareaStyle = {
    width: "100%",
    padding: "8px",
    boxSizing: "border-box",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f1f1f1",
  };

  return (
    <div style={containerStyle}>
      <label htmlFor={id} style={labelStyle}>{label}</label> <br />
      <textarea
        id={id}
        name={name}
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={textareaStyle}
      ></textarea>
    </div>
  );
};

export default TextArea;