import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import Modal from '../components/Modal';
import useDeviceType from '../utils/useDeviceType';
import axios from 'axios';

const ServiceDetails = () => {
  const { categoryName } = useParams();
  const deviceType = useDeviceType();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); 
  const [photos, setPhotos] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const API_URL = process.env.REACT_APP_API_URL;
  const APP_URL = process.env.REACT_APP_APP_URL;

  const fetchAllServices = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${API_URL}/get-product-data`, { category_name: categoryName });
      console.log(categoryName);
      console.log('API response:', response);
      
      const photos = response.data.data.map(product => `${APP_URL}/storage/${product.photo}`);
      setPhotos(photos);
    } catch (error) {
      console.error('Error fetching services:', error);
      setError('Failed to fetch services. Please try again later.'); 
    } finally {
      setLoading(false);
    }
  }, [API_URL, categoryName, APP_URL]);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
    setCurrentIndex(0);
  };

  return (
    <Layout>
      <div 
        style={
          deviceType === 'mobile' ? 
          { 
            padding:  '30px', 
            boxSizing: 'border-box', 
            backgroundColor: '#c8d8de', 
            height: 'auto', 
            width: '100%',
            display: 'grid',
            gap: '10px',
            justifyContent: 'center',
          } : 
          {
            padding:  '50px 100px',
            boxSizing: 'border-box', 
            backgroundColor: '#c8d8de', 
            height: '100%', 
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
            gap: '20px',
            justifyContent: 'center',
          }
        }
      > 

        {photos.map((photo, index) => (
          <div 
            key={index} 
            style={{
              height: '300px',
              boxSizing: 'border-box',
              padding: deviceType === 'mobile' ? '20px' : '15px',
              backgroundColor: 'white',
              borderRadius: deviceType === 'mobile' ? '33px' : '8px',
              cursor: 'pointer',
              marginBottom: deviceType === 'mobile' ? '20px' : '0',
            }}
            onClick={() => openModal(index)}
          >
            <img
              src={photo} 
              alt={`Service ${index + 1}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: deviceType === 'mobile' ? 'cover' : 'contain',
                borderRadius: deviceType === 'mobile' ? '33px' : '8px',
              }}
            />
          </div>
        ))}

        {/* Render the modal if open */}
        <Modal 
          isOpen={modalOpen}
          onClose={closeModal}
          photos={photos}
          currentIndex={currentIndex}
        />
      </div>
    </Layout>
  );
}

export default ServiceDetails;