import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Layout = ({children}) => {
  return (
    <div
    style={{
      width: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      // height: '100%',
    }}>
        <Navbar />
          {children}
        <div
        style={{
          height: '50vh',
        }}>
        <Footer/>
        </div>
    </div>
  )
}

export default Layout
