import React, { useState } from 'react';
import { headerMenus } from "../json/headerMenus";
import logo from "../assets/images/logo.png";
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../utils/useDeviceType';
import '../Navbar.css';

const Navbar = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const deviceType = useDeviceType();

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  return (
    <nav
      style={{
        height: "14vh",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div style={
        deviceType === "mobile" ? { width: "80%", display: "flex", justifyContent: "center", alignItems: "center" } : { width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }
      }>
        <img
          src={logo}
          style={
            deviceType === "mobile" ? { width: "auto", height: "auto" } : { width: "auto", height: "auto" }
          }
          alt="Logo"
        />
      </div>

      {deviceType === 'mobile' ? (
        <>
          <div className="hamburger" onClick={toggleMenu}
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "50px",
              cursor: "pointer",
            }}
          >
            {isMenuOpen ? '✖' : '☰'} {/* Cross icon when menu is open, hamburger icon when menu is closed */}
          </div>
          {isMenuOpen && (
            <ul className="mobile-menu">
              {headerMenus.map((menu, index) => (
                <li key={index}>
                  <p onClick={() => navigate(menu?.path)}>{menu?.name}</p>
                </li>
              ))}
              <li>
                <button className="portal-button">Portal Login</button>
              </li>
            </ul>
          )}
        </>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "85%" }}>
            <ul style={{
              listStyleType: "none",
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
              padding: 0,
              margin: 0,
            }}>
              {headerMenus.map((menu, index) => (
                <li key={index} style={{ fontSize: 16, cursor: "pointer", marginTop: "24px", width: "auto" }}>
                  <p
                    onClick={() => navigate(menu?.path)}
                    style={{
                      color: "#272A2C",
                      textDecoration: "none",
                      cursor: "pointer",
                      margin: 0,
                      fontWeight: "800",
                    }}
                  >
                    {menu?.name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ cursor: "pointer", marginTop: "24px", width: "14%" }}>
            <button className="portal-button">PORTAL LOGIN</button>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;